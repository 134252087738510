import {RuleObject} from 'ant-design-vue/es/form/interface';

export default () => {
  const checkPhoneCode = async (rule: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject('This field is required');
    }
    if (!value.startsWith('+')) {
      return Promise.reject('This field should start with +');
    }
    if (!/^\d+$/g.test(value.substring(1))) {
      return Promise.reject('Only digits are allowed after +');
    }
    return Promise.resolve();
  };
  const checkCurrency = async (rule: RuleObject, value: string) => {
    if (!value) {
      return Promise.reject('This field is required');
    }
    if (!/^[a-zA-Z]+$/g.test(value)) {
      return Promise.reject('Only letters are allowed');
    }
    return Promise.resolve();
  };
  const checkRegisterBonus = async (rule: RuleObject, value: number) => {
    if (!value && value !== 0) {
      return Promise.reject('This field is required');
    }
    if (isNaN(value)) {
      return Promise.reject('This field should be a number');
    }
    if (value < 0) {
      return Promise.reject('This field should be more than 0');
    }
    return Promise.resolve();
  };
  const checkInvitationBonus = async (rule: RuleObject, value: number) => {
    if (!value && value !== 0) {
      return Promise.reject('This field is required');
    }
    if (isNaN(value)) {
      return Promise.reject('This field should be a number');
    }
    if (value < 0) {
      return Promise.reject('This field should be more than 0');
    }
    return Promise.resolve();
  };
  const checkCurrencyDecimals = async (rule: RuleObject, value: number) => {
    if (!value && value !== 0) {
      return Promise.reject('This field is required');
    }
    if (!Number.isInteger(value)) {
      return Promise.reject('Only digits allowed');
    }
    return Promise.resolve();
  };
  const checkMinPhoneNumber = async (rule: RuleObject, value: number) => {
    if (!value && value !== 0) {
      return Promise.reject('This field is required');
    }
    if (!Number.isInteger(value)) {
      return Promise.reject('Only digits allowed');
    }
    if (value <= 0) {
      return Promise.reject('This field should be more than 0');
    }
    return Promise.resolve();
  };
  const localisationValidationRules = {
    country: [{required: true, message: 'This field is required', trigger: 'blur'}],
    phoneCode: [{validator: checkPhoneCode, trigger: 'blur'}],
    currency: [{validator: checkCurrency, trigger: 'blur'}],
    registerBonus: [{validator: checkRegisterBonus, trigger: 'blur'}],
    invitationBonus: [{validator: checkInvitationBonus, trigger: 'blur'}],
    currencyDecimals: [{validator: checkCurrencyDecimals, trigger: 'blur'}],
    minPhoneNumberLength: [{validator: checkMinPhoneNumber, trigger: 'blur'}],
  };

  return {localisationValidationRules};
};
