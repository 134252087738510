import {AxiosResponse} from 'axios';
import ApiService from '@/services/_helper/api.service';
import LocalisationInterface from '@/types/api/LocalisationInterface';

export default class LocalisationsService {
  static getLocalisations(params: {pageNumber: number; itemsCount: number}): Promise<AxiosResponse> {
    return ApiService.get('/localizations', {params});
  }
  static updateLocalisation(localisation: LocalisationInterface): Promise<AxiosResponse> {
    return ApiService.put(`/localizations/${localisation.id}`, localisation);
  }
  static deleteLocalisation(id: number): Promise<AxiosResponse> {
    return ApiService.delete(`/localizations/${id}`);
  }
  static addLocalisation(localisation: LocalisationInterface): Promise<AxiosResponse> {
    return ApiService.post('/localizations', localisation);
  }
}
